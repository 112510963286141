<template>
    <div class="ad-box">
        <div class="ad-header">
            <el-button type="primary" @click="add">新增广告</el-button>
        </div>
        <div class="ad-table">
            <c-table :tableDataUrl="tableDataUrl" :border="true" :isLoadData="true" ref="tableRef">
                <el-table-column type="index" width="55" label="序号" :align="'center'" />
                <el-table-column prop="image_url" label="广告图片">
                    <template #default="scope">
                        <el-image :src="scope.row.image_url" fit="fill" style="width:100px;height:100px" />
                    </template>
                </el-table-column>
                <el-table-column prop="image_link" label="链接地址" />
                <el-table-column prop="weight" label="权重" />
                <el-table-column prop="bidding" label="竞价" />
                <el-table-column prop="category" label="类别" />
                <el-table-column prop="view_type" label="显示页面" />
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link type="primary" @click="edit(scope.row)">编辑</el-link>
                        <el-link type="primary" style="margin-left:15px" @click="del(scope.row)">删除</el-link>
                    </template>
                </el-table-column>
            </c-table>
        </div>
        <el-dialog v-model="isShow">
            <template #header>
                新增广告
            </template>
            <el-form :model="form" label-width="120px" :rules="rules" inline-message ref="formRef">
                <el-form-item label="广告图片：" prop="image_url">
                    <c-upload ref="adImgRef" @success="uploadAdImg" text="广告图片" :onlyUrl="true" />
                    <el-input style="display:none" v-model="form.legal_person_id_card_front" />
                    <el-input type="hidden" v-model="form.image_url"></el-input>
                </el-form-item>
                <el-form-item label="链接地址：" prop="image_link">
                    <el-input v-model="form.image_link"></el-input>
                </el-form-item>
                <el-form-item label="权重：" prop="weight">
                    <el-input type='number' v-model.number="form.weight"></el-input>
                </el-form-item>
                <el-form-item label="竞价：" prop="bidding">
                    <el-input type="number" v-model.number="form.bidding"></el-input>
                </el-form-item>
                <el-form-item label="类别：" prop="category">
                    <el-input type="number" v-model.number="form.category"></el-input>
                </el-form-item>
                <el-form-item label="显示页面：" prop="view_type">
                    <el-input v-model="form.view_type"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="submit">确定</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import cTable from '@/components/CustomTable'
import CUpload from '@/components/CustomUpload'
import { ElMessage } from 'element-plus'
import { nextTick } from 'process';

const { proxy } = getCurrentInstance() // 当前实例
const tableDataUrl = proxy.$api.ad.list

const isShow = ref(false)
const isEdit = ref(false)
const form = ref({
    image_url: '',
    image_link: '',
    weight: '',
    bidding: '',
    category: '',
    view_type: '',
})
const rules = ref({
    image_url: [
        { required: true, message: '此项必填' }
    ],
    image_link: [
        { required: true, message: '此项必填', trigger: 'blur' }
    ],
    weight: [
        { required: true, message: '此项必填', trigger: 'blur' },
        { type: 'number', message: '权重类型为数字', trigger: 'blur' }
    ],
    bidding: [
        { required: true, message: '此项必填', trigger: 'blur' },
        { type: 'float', message: '竞价类型为小数浮点数', trigger: 'blur' }
    ],
    category: [
        { required: true, message: '此项必填', trigger: 'blur' },
        { type: 'number', message: '权重类型为数字', trigger: 'blur' }
    ],
    view_type: [
        { required: true, message: '此项必填', trigger: 'blur' }
    ],
})
const uploadAdImg = (imgUrl) => {
    form.value['image_url'] = imgUrl
}
const add = () => {
    isShow.value = true
}

const cancel = () => {
    isShow.value = false
}

const edit = (row) => {
    isShow.value = true
    isEdit.value = true
    form.value['image_url'] = row.image_url
    form.value['image_link'] = row.image_link
    form.value['weight'] = row.weight
    form.value['bidding'] = row.bidding
    form.value['category'] = row.category
    form.value['view_type'] = row.view_type
    form.value['id'] = row.id

    nextTick(() => {
        proxy.$refs.adImgRef.setImgUrl(row.image_url)
    })

}

const del = (row) => {
    proxy.$api.ad.del({ id: row.id }).then(r => {
        if (r.status == 200) {
            ElMessage({ type: 'success', message: r.data.message })
            proxy.$refs.tableRef.refresh()
        }
    })
}

const submit = () => {
    proxy.$refs.formRef.validate((validate) => {
        if (validate) {
            if (isEdit.value) {
                proxy.$api.ad.edit(form.value).then(r => {
                    response(r)
                })
            } else {
                proxy.$api.ad.add(form.value).then(r => {
                    response(r)
                })
            }

        }
    })
}

const response = (res) => {
    if (res.status == 200) {
        ElMessage({ type: 'success', message: isEdit.value ? '修改成功' : '创建成功' })
    } else {
        ElMessage({ type: 'error', message: isEdit.value ? '修改失败' : '创建失败' })
    }
    isShow.value = false
    proxy.$refs.tableRef.refresh()
    proxy.$refs.formRef.resetFields()
    proxy.$refs.adImgRef.setImgUrl('')
}
</script>

<style lang="less">
.ad-header {
    margin-bottom: 15px;
    text-align: right;
}
</style>